export const environment = {
  appName: "WHITELOTUS ADMIN",
  production: true,

  apiUrl:'https://api.hotelwhitelotus.in/',  //production
  //apiUrl:'https://apihwl.uat-demo.com/', //development

  media_domain: 'https://d2gvsnjeeksddg.cloudfront.net/production', //production
  //media_domain: 'https://d2gvsnjeeksddg.cloudfront.net/development',   //development

  apiPrefix: 'api/v1',

};
